<template>
    <div class="user-edit">
        <div class="main-h1">Создание пользователя</div>
        <div class="card">
            <div class="card-body">
                <form v-on:submit.prevent>
                    <table class="table">
                        <tr>
                            <td>Login:</td>
                            <td><input v-model="data.login" required></td>
                        </tr>
                        <tr>
                            <td>Город:</td>
                            <td>
                                <select v-model="data.city_id" required>
                                    <option v-for="city in cities" :value="city.id">{{city.title}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Организационно правовая форма:</td>
                            <td>
                                <select v-model="data.type" required>
                                    <option v-for="type in userOrgTypes" :value="type">{{type}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Имя:</td>
                            <td><input v-model="data.name" required></td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td><input v-model="data.email" required></td>
                        </tr>
                        <tr>
                            <td>Номер телефона:</td>
                            <td><input v-model="data.phone" required></td>
                        </tr>
                        <tr>
                            <td>Роли:</td>
                            <td>
                                <label v-for="role in roles.filter(item=>{return $store.state.isAdmin() || (item.name !== 'admin' && item.name !== 'implementor')})">
                                    <input type="checkbox" :value="role.id" v-model="data.roles"> {{role.title}}
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>Пароль</td>
                            <td><input v-model="data.password" type="password" required></td>
                        </tr>
                        <tr>
                            <td>Повторите пароль</td>
                            <td><input v-model="data.password2" type="password" required></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button class="btn green" @click="sendData" :disabled="requestInProgress">Создать</button>
                            </td>
                        </tr>
                    </table>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import userOrgTypes from "@/helpers/userOrgTypes";
export default {
    name: "userCreate",
    data() {
        return {
            data:{
                roles:[],
                cities:[],
            },
            roles:[],
            requestInProgress:false,
            userOrgTypes:userOrgTypes
        }
    },
    methods: {
        getRoles() {
            this.$api.get(`user/roles`).then(res => {
                if (res.data.success) {
                    this.roles = res.data.data
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                }
            })
        },
        sendData(){
            this.requestInProgress = true;
            this.$api.post(`user`,this.data).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.$store.commit('showNotify',{title:'Пользователь создан',content:'Пользователь успешно создан.',type:'success'})
                    this.$router.push('/panel/user');
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'})
                }
            })
        },
    },
    created() {
        this.getRoles();
        this.getCities();
    }
}
</script>

<style lang="scss" scoped>
    .user-edit {

    }
</style>